@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Julius+Sans+One&display=swap");
.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 40px 20px 40px;
}

.navbar #desktop-ul {
  display: none;
}

.navbar #logo {
  font-size: 30px;
  text-align: left;
  width: 50%;
}

.navbar #logo a {
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: #000000;
}

.navbar #logo a:hover {
  color: #d67992;
}

.navbar #nav-menu {
  width: 50%;
  text-align: right;
  font-size: 40px;
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.navbar #nav-menu:hover {
  color: #d67992;
}

.navbar #vertical-line {
  display: none;
}

.navbar #right-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar .lower-nav {
  width: 100%;
  margin-top: 10px;
}

.navbar .lower-nav a {
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: #000000;
  margin-right: 20px;
  font-size: 30px;
}

.navbar .lower-nav .github:hover {
  color: #fcd45d;
}

.navbar .lower-nav .linkedin:hover {
  color: #247ba0;
}

.navbar .lower-nav .instagram:hover {
  color: #d67992;
}

@media screen and (min-width: 768px) {
  .navbar {
    padding: 60px 60px 40px 60px;
  }
  .navbar #logo {
    font-size: 50px;
  }
  .navbar #nav-menu {
    font-size: 65px;
  }
  .navbar #projects-dropdown {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .navbar #logo {
    width: 76px;
  }
  .navbar #desktop-ul {
    display: block;
  }
  .navbar #desktop-ul #home-link {
    display: none;
  }
  .navbar #desktop-ul li {
    display: inline-block;
    /* Fade in */
  }
  .navbar #desktop-ul li a, .navbar #desktop-ul li span {
    text-decoration: none;
    font-family: "Julius Sans One", sans-serif;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-size: 20px;
    color: #000000;
    margin-left: 70px;
    display: block;
    position: relative;
    padding: 0.2em 0;
  }
  .navbar #desktop-ul li a:hover, .navbar #desktop-ul li span:hover {
    color: #d67992;
  }
  .navbar #desktop-ul li a::after, .navbar #desktop-ul li span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #d67992;
    opacity: 0;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .navbar #desktop-ul li a:hover::after,
  .navbar #desktop-ul li a:focus::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0.2em, 0);
            transform: translate3d(0, 0.2em, 0);
  }
  .navbar #desktop-ul li span:hover::after, .navbar #desktop-ul li span:focus::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0.2em, 0);
            transform: translate3d(0, 0.2em, 0);
  }
  .navbar #desktop-ul li #projects-dropdown {
    display: block;
    position: absolute;
    top: 108px;
    padding: 0 0 0 50px;
    z-index: 10;
  }
  .navbar #desktop-ul li #projects-dropdown ul {
    padding: 20px;
    background-color: #fefafb;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 0 0 10px 10px;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
  }
  .navbar #desktop-ul li #projects-dropdown ul li {
    display: block;
  }
  .navbar #desktop-ul li #projects-dropdown ul li a {
    margin: 0 0 10px 0;
    font-size: 16px;
  }
  .navbar #nav-menu {
    display: none;
  }
  .navbar #right-nav {
    width: 725px;
  }
  .navbar #vertical-line {
    display: inline-block;
    border-left: 1px solid #000000;
    height: 55px;
    margin: 0 10px;
  }
  .navbar .lower-nav {
    width: auto;
  }
}

@media screen and (min-width: 1440px) {
  .navbar #right-nav {
    width: 850px;
  }
  .navbar #right-nav #desktop-ul #home-link {
    display: inline-block;
  }
}

#mobile-menu {
  background-color: #f0cad4f7;
  padding: 50px;
  width: 100%;
  position: absolute;
  z-index: 10;
}

#mobile-menu ul {
  list-style: none;
}

#mobile-menu ul li {
  text-align: center;
}

#mobile-menu ul li a, #mobile-menu ul li span {
  text-decoration: none;
  font-family: "Julius Sans One", sans-serif;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: black;
  display: inline-block;
  margin: 15px 0;
  font-size: 25px;
}

#mobile-menu ul li a:hover {
  color: #d67992;
}

#mobile-menu ul li #projects-dropdown-mobile ul {
  margin: 20px 0;
}

#mobile-menu ul li #projects-dropdown-mobile ul li {
  display: block;
}

#mobile-menu ul li #projects-dropdown-mobile ul li a {
  margin: 0 0 20px 0;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  #mobile-menu ul li a, #mobile-menu ul li span {
    margin-bottom: 35px;
    font-size: 35px;
  }
  #mobile-menu ul li #projects-dropdown-mobile ul {
    margin-bottom: 20px;
  }
  #mobile-menu ul li #projects-dropdown-mobile ul li {
    display: block;
  }
  #mobile-menu ul li #projects-dropdown-mobile ul li a {
    margin: 0 0 20px 0;
    font-size: 20px;
  }
}

#hero {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 305px;
}

#hero #hero-text-container {
  text-align: center;
  width: 100%;
}

#hero #hero-text-container h1 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}

#hero #hero-text-container #h2-container {
  font-family: "Julius Sans One", sans-serif;
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 20px;
}

#hero #hero-text-container #h2-container h2 {
  display: inline-block;
}

#hero #hero-text-container #h2-container #holliday {
  margin-left: 10px;
  color: #247ba0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

#hero #hero-text-container #desktop-tagline {
  font-family: "Didact Gothic", sans-serif;
  display: none;
  font-size: 18px;
}

#hero img {
  width: 100%;
  z-index: 3;
  -webkit-animation: image-fade 3s;
          animation: image-fade 3s;
}

#hero #mobile-tagline {
  margin-top: 10px;
  font-family: "Didact Gothic", sans-serif;
  width: 100%;
  font-size: 18px;
  text-align: center;
  -webkit-animation: text-fade 3s;
          animation: text-fade 3s;
}

@media screen and (min-width: 768px) {
  #hero {
    margin: 40px 90px 90px;
    margin-left: auto;
    margin-right: auto;
    max-width: 588px;
    max-height: 401px;
  }
  #hero #hero-text-container {
    text-align: left;
    width: 40%;
    -webkit-animation: 2s text-fade;
            animation: 2s text-fade;
  }
  #hero #hero-text-container h1 {
    margin-top: 40px;
    font-size: 80px;
  }
  #hero #hero-text-container #h2-container {
    margin-bottom: 0;
  }
  #hero #hero-text-container h2 {
    font-size: 30px;
    margin-bottom: 65px;
  }
  #hero #hero-text-container #desktop-tagline {
    display: block;
    font-size: 20px;
  }
  #hero img {
    width: 60%;
    -webkit-animation: image-fade 3s;
            animation: image-fade 3s;
  }
  #hero #mobile-tagline {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  #hero {
    margin: 80px 170px;
    max-width: 684px;
    margin-left: auto;
    margin-right: auto;
    max-height: 443px;
  }
  #hero #hero-text-container {
    width: 43%;
  }
  #hero #hero-text-container h1 {
    margin-top: 40px;
    font-size: 100px;
  }
  #hero #hero-text-container #h2-container #holliday:hover {
    scale: 1.1;
    margin-left: 20px;
    cursor: default;
  }
  #hero #hero-text-container h2 {
    font-size: 40px;
    margin-bottom: 50px;
  }
  #hero #hero-text-container #desktop-tagline {
    font-size: 20px;
  }
  #hero img {
    width: 57%;
  }
}

@media screen and (min-width: 1440px) {
  #hero {
    margin: 80px 170px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    max-height: 641px;
  }
  #hero #hero-text-container {
    width: 45%;
  }
  #hero #hero-text-container h1 {
    margin-top: 40px;
    font-size: 150px;
  }
  #hero #hero-text-container h2 {
    font-size: 63px;
    margin-bottom: 60px;
  }
  #hero #hero-text-container #desktop-tagline {
    font-size: 30px;
  }
  #hero img {
    width: 55%;
  }
}

#skills-container {
  background-image: url(./../img/backgroundimg.png);
  background-attachment: fixed;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
  width: 100%;
  height: 100%;
}

#skills-container h2 {
  font-size: 20px;
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 40px;
}

#skills-container #icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  row-gap: 40px;
}

#skills-container #icon-container .service-card {
  text-align: center;
  width: 45%;
}

#skills-container #icon-container .service-card #icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 30px;
  background-color: #ffffffb5;
  border: 4px solid #d67992;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: auto;
  margin-bottom: 5px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#skills-container #icon-container .service-card #icon svg {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#skills-container #icon-container .service-card #icon:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

#skills-container #icon-container .service-card #icon:hover svg {
  scale: 1.1;
}

#skills-container #icon-container .service-card h3 {
  width: 100%;
  font-size: 15px;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 8px;
}

#skills-container #icon-container .service-card p {
  width: 100%;
  font-size: 13px;
  font-family: "Didact Gothic", sans-serif;
}

@media screen and (min-width: 768px) {
  #skills-container {
    padding: 100px 60px;
  }
  #skills-container h2 {
    font-size: 25px;
    text-align: left;
  }
  #skills-container #icon-container .service-card {
    width: 22%;
  }
}

@media screen and (min-width: 1024px) {
  #skills-container {
    padding: 150px 70px;
  }
  #skills-container h2 {
    font-size: 35px;
    font-weight: 100;
  }
  #skills-container #icon-container .service-card {
    width: 22%;
  }
  #skills-container #icon-container .service-card #icon {
    width: 130px;
    height: 130px;
    font-size: 55px;
    margin-bottom: 15px;
  }
  #skills-container #icon-container .service-card h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  #skills-container #icon-container .service-card p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  #skills-container #content-container {
    max-width: 1000px;
    margin: auto;
  }
  #skills-container h2 {
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 70px;
  }
  #skills-container #icon-container .service-card {
    width: 23%;
  }
  #skills-container #icon-container .service-card #icon {
    width: 130px;
    height: 130px;
    font-size: 55px;
    margin-bottom: 15px;
  }
  #skills-container #icon-container .service-card h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  #skills-container #icon-container .service-card p {
    font-size: 18px;
  }
}

#hm-pg-projects-container {
  width: 100%;
  padding: 60px 60px 40px 60px;
}

#hm-pg-projects-container #projects-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  row-gap: 30px;
  max-width: 305px;
}

#hm-pg-projects-container #projects-container h2 {
  width: 100%;
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

#hm-pg-projects-container #projects-container #carousel-fade {
  display: none;
  height: 100%;
  width: 200px;
  position: absolute;
  z-index: 6;
  top: 0;
  right: -1px;
  background: #fefafb;
  background: -webkit-gradient(linear, left top, right top, from(rgba(254, 250, 251, 0.00466124)), color-stop(0%, rgba(254, 250, 251, 0)), to(#fefafb));
  background: linear-gradient(90deg, rgba(254, 250, 251, 0.00466124) 0%, rgba(254, 250, 251, 0) 0%, #fefafb 100%);
}

#hm-pg-projects-container #projects-container .projects-slider {
  width: 100%;
  height: auto;
}

#hm-pg-projects-container #projects-container .projects-slider .carousel-item-padding {
  padding: 0 10px;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #E8EDF2;
  padding: 40px 25px;
  border-radius: 30px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card h2 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 20px;
  margin-bottom: 30px;
  pointer-events: none;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card h3 {
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
  pointer-events: none;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card img {
  width: 100%;
  height: 178px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 20px;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card a {
  text-decoration: none;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card button {
  padding: 20px;
  width: auto;
  border: none;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  margin: auto;
  background-color: #247ba0;
  cursor: pointer;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card button:hover {
  background-color: #d67992;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card:hover {
  background-color: #C7DAE4;
}

#hm-pg-projects-container #projects-container .projects-slider .project-card:hover img {
  scale: 1.1;
}

@media screen and (min-width: 600px) {
  #hm-pg-projects-container #projects-container {
    max-width: 568px;
  }
  #hm-pg-projects-container #projects-container .projects-slider .carousel-item-padding {
    padding: 0 10px;
  }
}

@media screen and (min-width: 768px) {
  #hm-pg-projects-container {
    padding: 100px;
  }
  #hm-pg-projects-container #projects-container {
    max-width: 568px;
  }
  #hm-pg-projects-container #projects-container h2 {
    font-size: 25px;
    text-align: left;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card h2 {
    text-align: center;
    font-family: "Julius Sans One", sans-serif;
    font-size: 20px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  #hm-pg-projects-container {
    padding: 150px 70px;
  }
  #hm-pg-projects-container #projects-container {
    max-width: 926px;
  }
  #hm-pg-projects-container #projects-container h2 {
    font-size: 35px;
    font-weight: 100;
  }
  #hm-pg-projects-container #projects-container #carousel-fade {
    display: block;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card {
    padding: 50px 30px;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card h3 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  #hm-pg-projects-container {
    margin: 0;
    width: 100%;
  }
  #hm-pg-projects-container #projects-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 22px;
            column-gap: 22px;
    max-width: 1300px;
    margin: auto;
  }
  #hm-pg-projects-container #projects-container h2 {
    font-size: 40px;
    margin-bottom: 50px;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card {
    padding: 50px 27px;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card h2 {
    font-size: 25px;
  }
  #hm-pg-projects-container #projects-container .projects-slider .project-card img {
    width: 90%;
  }
}

#background-curve-cont {
  position: relative;
}

#background-curve-cont .bg-curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

#background-curve-cont .bg-curve svg {
  width: calc(100% + 1.3px);
  height: 68px;
}

#background-curve-cont .bg-curve .shape-fill {
  fill: #FEFAFB;
}

#contact-form-section {
  padding: 120px 60px 60px 60px;
  /* ff 3.6+ */
  /* safari 5.1+,chrome 10+ */
  /* opera 11.10+ */
  background: -o-linear-gradient(145deg, rgba(252, 212, 93, 0.51) 0%, rgba(240, 202, 212, 0.47) 30%, rgba(199, 218, 228, 0.51) 61%, rgba(240, 202, 212, 0.49) 100%);
  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F0CAD4', endColorstr='#FCD45D', GradientType=1 );
  /* ie 10+ */
  /* global 94%+ browsers support */
  background: linear-gradient(145deg, rgba(252, 212, 93, 0.51) 0%, rgba(240, 202, 212, 0.47) 30%, rgba(199, 218, 228, 0.51) 61%, rgba(240, 202, 212, 0.49) 100%);
  background-size: 400% 400%;
  -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
  height: 100%;
}

#contact-form-section h2 {
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 40px;
  text-align: center;
}

#contact-form-section #contactform-tagline-mobile, #contact-form-section #contactform-tagline {
  font-size: 16px;
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
  margin-bottom: 30px;
}

#contact-form-section #contactform-tagline {
  display: none;
}

#contact-form-section #contactform {
  z-index: 1;
}

#contact-form-section #contactform label {
  display: block;
  width: 100%;
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin: 0 0 5px 8px;
}

#contact-form-section #contactform input, #contact-form-section #contactform textarea {
  display: block;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  background-color: #ffffffb3;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  resize: none;
}

#contact-form-section #contactform textarea {
  height: 150px;
}

#contact-form-section #contactform button {
  padding: 20px;
  width: auto;
  border: none;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background-color: #d67992;
  font-size: 13px;
  padding: 10px 20px;
  margin: 20px 0;
  cursor: pointer;
}

#contact-form-section #contactform .success, #contact-form-section #contactform .danger {
  font-family: "Didact Gothic", sans-serif;
  font-size: 13px;
}

#contact-form-section #contactform .success {
  background-color: #91c591;
  padding: 10px 20px;
  border-radius: 10px;
  width: auto;
  display: inline-block;
}

#contact-form-section #contactform .danger {
  background-color: #f4a244;
  padding: 10px 20px;
  border-radius: 10px;
  width: auto;
  display: inline-block;
}

#contact-form-section #contactform input:focus, #contact-form-section #contactform textarea:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
  -moz-box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
}

#contact-form-section #contactform button:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -webkit-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -moz-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: 768px) {
  #background-curve-cont .bg-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 93px;
  }
  #contact-form-section {
    padding: 150px 100px 60px 100px;
  }
  #contact-form-section h2 {
    font-size: 25px;
  }
  #contact-form-section #contactform-tagline-mobile {
    display: none;
  }
  #contact-form-section #contactform-tagline {
    display: block;
  }
  #contact-form-section #contactform {
    max-width: 526px;
    margin: auto;
  }
  #contact-form-section #contactform label {
    font-size: 18px;
  }
  #contact-form-section #contactform input, #contact-form-section #contactform textarea {
    font-size: 16px;
  }
  #contact-form-section #contactform button {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  #contact-form-section {
    padding: 200px 150px 150px;
  }
  #contact-form-section h2 {
    font-size: 35px;
    font-weight: 100;
  }
  #contact-form-section #contactform-tagline {
    font-size: 18px;
    margin-bottom: 50px;
  }
  #contact-form-section #contactform {
    max-width: 526px;
  }
  #contact-form-section #contactform label {
    font-size: 18px;
  }
  #contact-form-section #contactform input, #contact-form-section #contactform textarea {
    font-size: 16px;
  }
  #contact-form-section #contactform button {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  #contact-form-section {
    padding: 200px 150px 150px;
  }
  #contact-form-section h2 {
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 70px;
  }
  #contact-form-section #contactform-tagline {
    font-size: 18px;
    margin: auto;
    margin-bottom: 50px;
    max-width: 900px;
  }
  #contact-form-section #contactform {
    max-width: 540px;
  }
  #contact-form-section #contactform label {
    font-size: 18px;
  }
  #contact-form-section #contactform input, #contact-form-section #contactform textarea {
    font-size: 16px;
  }
  #contact-form-section #contactform button {
    font-size: 18px;
  }
}

#chatgpt-section {
  padding: 60px;
  background: #fefafb;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(254, 250, 251, 0)), to(#f0cad4));
  background: linear-gradient(180deg, rgba(254, 250, 251, 0) 0%, #f0cad4 100%);
}

#chatgpt-section #chatgpt-content-container h2 {
  font-size: 20px;
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 20px;
}

#chatgpt-section #chatgpt-content-container p {
  text-align: center;
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

#chatgpt-section #chatgpt-content-container #gpt-info-text {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #975869;
}

#chatgpt-section #chatgpt-content-container #gpt-info-text p {
  margin: 0 0 20px 5px;
}

#chatgpt-section #chatgpt-content-container #gpt-explanation-hidden {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  background-color: #E8EDF2;
}

#chatgpt-section #chatgpt-content-container #gpt-explanation-hidden a {
  color: black;
  cursor: pointer;
}

#chatgpt-section #chatgpt-content-container #gpt-response {
  line-height: 30px;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form label {
  display: none;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 20px 0;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container input {
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-right: 10px;
  background-color: #ffffffb3;
  -webkit-box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
  resize: none;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container button {
  padding: 20px;
  width: auto;
  border: none;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background-color: #d67992;
  font-size: 13px;
  padding: 10px 20px;
  cursor: pointer;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container input:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
  -moz-box-shadow: 0px 0px 10px 0px rgba(240, 202, 212, 0.75) inset;
}

#chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container button:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -webkit-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -moz-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
}

@media screen and (min-width: 768px) {
  #chatgpt-section {
    padding: 100px;
  }
  #chatgpt-section #chatgpt-content-container {
    max-width: 500px;
    margin: auto;
  }
  #chatgpt-section #chatgpt-content-container h2 {
    font-size: 25px;
    margin-bottom: 40px;
  }
  #chatgpt-section #chatgpt-content-container p {
    text-align: left;
    font-size: 16px;
  }
  #chatgpt-section #chatgpt-content-container #gpt-info-text {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #chatgpt-section #chatgpt-content-container #gpt-info-text p {
    margin: 0 0 20px 5px;
  }
  #chatgpt-section #chatgpt-content-container #gpt-explanation-hidden {
    padding: 15px;
  }
  #chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container {
    margin: 40px 0;
  }
  #chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container input {
    font-size: 16px;
  }
  #chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container button {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  #chatgpt-section {
    padding: 150px 100px;
  }
  #chatgpt-section #chatgpt-content-container h2 {
    font-size: 35px;
    font-weight: 100;
  }
  #chatgpt-section #chatgpt-content-container #gpt-section-tagline {
    font-size: 18px;
  }
  #chatgpt-section #chatgpt-content-container #gpt-info-text p {
    font-size: 18px;
  }
  #chatgpt-section #chatgpt-content-container #gpt-info-text svg {
    font-size: 20px;
    margin-top: 4px;
  }
}

@media screen and (min-width: 1200px) {
  #chatgpt-section #chatgpt-content-container {
    max-width: 1000px;
  }
  #chatgpt-section #chatgpt-content-container h2 {
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 70px;
  }
  #chatgpt-section #chatgpt-content-container p {
    font-size: 20px;
    text-align: center;
  }
  #chatgpt-section #chatgpt-content-container #gpt-info-text {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #chatgpt-section #chatgpt-content-container #gpt-explanation-hidden {
    font-size: 16px;
  }
  #chatgpt-section #chatgpt-content-container #chatgpt-form #gpt-input-flex-container input {
    font-size: 18px;
  }
  #chatgpt-section #chatgpt-content-container #chatgpt-form p {
    text-align: left;
    font-size: 16px;
  }
}

#about-section {
  width: 100%;
  padding: 40px 60px 60px 60px;
}

#about-section #about-content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#about-section #about-content-container h2 {
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

#about-section #about-content-container img {
  width: 90%;
  max-width: 375px;
  margin: auto;
  margin-bottom: 30px;
}

#about-section #about-content-container #about-text-container {
  margin: auto;
}

#about-section #about-content-container #about-text-container p {
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
}

#about-section #about-content-container #about-text-container p:nth-child(3) {
  margin-bottom: 20px;
}

#about-section #about-content-container #about-text-container a {
  text-decoration: none;
}

#about-section #about-content-container #about-text-container button {
  padding: 20px;
  width: auto;
  border: none;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background-color: #247ba0;
  font-size: 13px;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
}

#about-section #about-content-container #about-text-container button:nth-child(1) {
  margin: 0 5px 10px 0;
}

#about-section #about-content-container #about-text-container button:hover {
  background-color: #d67992;
}

#about-section #about-content-container #about-text-container button:hover:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -webkit-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
  -moz-box-shadow: 0px 0px 10px 0px #ac0c38 inset;
}

#about-section #about-content-container #about-text-container button:focus {
  outline: none;
  box-shadow: 0px 0px 10px 0px #18556e inset;
  -webkit-box-shadow: 0px 0px 10px 0px #18556e inset;
  -moz-box-shadow: 0px 0px 10px 0px #18556e inset;
}

@media screen and (min-width: 768px) {
  #about-section {
    padding: 100px 60px;
  }
  #about-section #about-content-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  #about-section #about-content-container h2 {
    font-size: 25px;
    text-align: left;
    margin-bottom: 40px;
  }
  #about-section #about-content-container img {
    width: 35%;
    margin: 0;
    margin-right: 30px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: top;
       object-position: top;
  }
  #about-section #about-content-container #about-text-container {
    width: 60%;
  }
  #about-section #about-content-container #about-text-container p {
    font-size: 16px;
  }
  #about-section #about-content-container #about-text-container button {
    font-size: 14px;
  }
  #about-section #about-content-container #about-text-container button:nth-child(1) {
    margin: 0 5px 10px 0;
  }
}

@media screen and (min-width: 1024px) {
  #about-section {
    padding: 150px;
  }
  #about-section #about-content-container h2 {
    font-size: 35px;
    font-weight: 100;
    text-align: left;
    margin-bottom: 40px;
  }
  #about-section #about-content-container img {
    width: 40%;
  }
  #about-section #about-content-container #about-text-container {
    width: 55%;
  }
  #about-section #about-content-container #about-text-container button {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  #about-section {
    padding: 150px;
  }
  #about-section #about-content-container {
    max-width: 1000px;
    margin: auto;
  }
  #about-section #about-content-container h2 {
    font-size: 40px;
    margin-bottom: 70px;
  }
  #about-section #about-content-container img {
    width: 40%;
    margin-right: 50px;
  }
  #about-section #about-content-container #about-text-container {
    width: 54%;
  }
  #about-section #about-content-container #about-text-container p {
    font-size: 18px;
  }
  #about-section #about-content-container #about-text-container p:nth-child(3) {
    margin-bottom: 50px;
  }
  #about-section #about-content-container #about-text-container button {
    font-size: 18px;
  }
}

#language-skills-section {
  padding: 60px;
}

#language-skills-section h2 {
  font-size: 20px;
  font-family: "Julius Sans One", sans-serif;
  text-align: center;
  margin-bottom: 40px;
}

#language-skills-section #language-icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  max-width: 329px;
}

#language-skills-section #language-icon-container .language-block {
  margin-bottom: 25px;
}

#language-skills-section #language-icon-container .language-block .language-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 30px;
  background-color: #ffffffb5;
  border: 4px solid #d67992;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 15px 10px 15px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#language-skills-section #language-icon-container .language-block .language-icon .lang-logo {
  width: 40%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#language-skills-section #language-icon-container .language-block .language-icon:hover .lang-logo {
  scale: 1.2;
}

#language-skills-section #language-icon-container .language-block .language-icon:hover {
  background-color: #E8EDF2;
}

#language-skills-section #language-icon-container .language-block p {
  text-align: center;
  font-family: "Didact Gothic", sans-serif;
}

@media screen and (min-width: 768px) {
  #language-skills-section {
    padding: 100px;
  }
  #language-skills-section h2 {
    font-size: 25px;
  }
  #language-skills-section #language-icon-container {
    max-width: 549px;
  }
}

@media screen and (min-width: 1024px) {
  #language-skills-section {
    padding: 100px;
  }
  #language-skills-section h2 {
    font-size: 35px;
    font-weight: 100;
  }
  #language-skills-section #language-icon-container {
    max-width: 549px;
  }
  #language-skills-section #language-icon-container .language-block .language-icon {
    width: 100px;
    height: 100px;
  }
  #language-skills-section #language-icon-container .language-block .language-icon .lang-logo {
    width: 40%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (min-width: 1200px) {
  #language-skills-section {
    padding: 150px;
  }
  #language-skills-section h2 {
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 70px;
  }
  #language-skills-section #language-icon-container {
    max-width: 700px;
  }
  #language-skills-section #language-icon-container .language-block .language-icon {
    width: 120px;
    height: 120px;
  }
  #language-skills-section #language-icon-container .language-block .language-icon .lang-logo {
    width: 40%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.spec-project-container .project-hero-container {
  padding: 60px 60px 20px 60px;
  background: #fefafb;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(240, 202, 212, 0.7)), color-stop(88.14%, rgba(214, 121, 146, 0)));
  background: linear-gradient(180deg, rgba(240, 202, 212, 0.7) 0%, rgba(214, 121, 146, 0) 88.14%);
}

.spec-project-container .project-hero-container h2 {
  font-size: 20px;
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
}

.spec-project-container .project-hero-container h3 {
  font-size: 18px;
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 50px;
}

.spec-project-container .project-hero-container img {
  width: 100%;
}

.spec-project-container .project-content-container {
  padding: 20px 60px 60px 60px;
}

.spec-project-container .project-content-container .slider {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.spec-project-container .project-content-container #left-arrow, .spec-project-container .project-content-container #right-arrow {
  margin-top: 15px;
  border: none;
  font-size: 30px;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.spec-project-container .project-content-container #left-arrow:hover, .spec-project-container .project-content-container #right-arrow:hover {
  color: #247ba0;
}

.spec-project-container .project-content-container .carousel-item-padding {
  padding: 10px;
}

.spec-project-container .project-content-container p {
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 20px;
}

.spec-project-container .project-content-container .bottom-text {
  margin-bottom: 40px;
}

.spec-project-container .project-content-container .carousel-img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 400px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.spec-project-container .project-content-container .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.spec-project-container .project-content-container .modal .modal-img {
  max-width: 90%;
  max-height: 90%;
}

.spec-project-container .project-content-container .modal #exit {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #247ba0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.spec-project-container .project-content-container .modal #exit:hover {
  scale: 1.4;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  color: #C7DAE4;
}

.spec-project-container .project-linkbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.spec-project-container .project-linkbtn img, .spec-project-container .project-linkbtn svg {
  cursor: pointer;
  color: black;
  font-size: 50px;
  width: 50px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  margin: 0 15px;
}

.spec-project-container .project-linkbtn img:hover {
  scale: 1.1;
}

.spec-project-container .project-linkbtn svg:hover {
  scale: 1.1;
  color: #247ba0;
}

.spec-project-container .project-tools {
  padding: 20px 60px 0 60px;
}

.spec-project-container .project-tools h3 {
  font-size: 18px;
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  margin-bottom: 50px;
}

.spec-project-container .project-tools #tools-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 360px;
  margin: auto;
}

.spec-project-container .project-tools #tools-container a {
  width: 30%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.spec-project-container .project-tools #tools-container a img {
  width: 50%;
  margin: auto;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.spec-project-container .project-tools #tools-container a img:hover {
  scale: 1.1;
}

@media screen and (min-width: 768px) {
  .spec-project-container .project-hero-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 100px 100px 20px 100px;
  }
  .spec-project-container .project-hero-container h2 {
    font-size: 25px;
  }
  .spec-project-container .project-hero-container h3 {
    font-size: 20px;
  }
  .spec-project-container .project-hero-container img {
    width: 80%;
    margin: auto;
  }
  .spec-project-container .project-content-container {
    padding: 20px 100px 100px 100px;
  }
  .spec-project-container .project-content-container p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .spec-project-container .project-content-container .bottom-text {
    margin-bottom: 50px;
  }
  .spec-project-container .project-content-container .carousel-img {
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    height: 350px;
  }
  .spec-project-container .project-content-container .modal .modal-img {
    margin-top: 40px;
  }
  .spec-project-container .project-linkbtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .spec-project-container .project-linkbtn img {
    cursor: pointer;
    width: 50px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  .spec-project-container .project-linkbtn img:hover {
    scale: 1.1;
  }
  .spec-project-container .project-tools {
    padding: 20px 100px 0 100px;
  }
  .spec-project-container .project-tools h3 {
    font-size: 20px;
  }
  .spec-project-container .project-tools #tools-container {
    max-width: 650px;
  }
  .spec-project-container .project-tools #tools-container a {
    width: 16%;
  }
}

@media screen and (min-width: 1024px) {
  .spec-project-container .project-hero-container {
    padding: 150px 100px 20px 100px;
  }
  .spec-project-container .project-hero-container h2 {
    font-size: 35px;
    font-weight: 100;
    text-align: left;
  }
  .spec-project-container .project-hero-container h3 {
    font-size: 25px;
    text-align: left;
    margin-bottom: 100px;
  }
  .spec-project-container .project-hero-container img {
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
  }
  .spec-project-container .project-content-container {
    padding: 20px 100px 100px 100px;
  }
  .spec-project-container .project-content-container .slider #left-arrow, .spec-project-container .project-content-container .slider #right-arrow {
    font-size: 40px;
  }
  .spec-project-container .project-content-container p {
    font-size: 18px;
    max-width: 900px;
    margin: auto;
    margin-bottom: 20px;
  }
  .spec-project-container .project-content-container .bottom-text {
    margin-bottom: 100px;
  }
  .spec-project-container .project-content-container .carousel-img:hover {
    scale: 1.1;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  .spec-project-container .project-content-container .modal .modal-img {
    margin-top: 80px;
  }
  .spec-project-container .project-content-container .modal #exit {
    top: 50px;
    right: 200px;
    font-size: 30px;
  }
  .spec-project-container .project-linkbtn {
    margin: 0 0 100px 0;
  }
  .spec-project-container .project-linkbtn img:hover {
    scale: 1.2;
  }
  .spec-project-container .project-tools {
    padding: 20px 100px 40px 100px;
  }
  .spec-project-container .project-tools h3 {
    font-size: 25px;
  }
  .spec-project-container .project-tools #tools-container {
    max-width: 650px;
  }
  .spec-project-container .project-tools #tools-container a {
    width: 16%;
  }
}

@media screen and (min-width: 1200px) {
  .spec-project-container .project-hero-container {
    padding: 150px 200px 20px 200px;
  }
  .spec-project-container .project-hero-container h2 {
    font-size: 40px;
  }
  .spec-project-container .project-hero-container h3 {
    font-size: 30px;
  }
  .spec-project-container .project-hero-container img {
    width: 60%;
  }
  .spec-project-container .project-content-container {
    padding: 20px 150px 100px 150px;
  }
  .spec-project-container .project-content-container p {
    max-width: 1200px;
    margin-bottom: 20px;
  }
  .spec-project-container .project-tools {
    padding: 20px 100px 40px 100px;
  }
  .spec-project-container .project-tools h3 {
    font-size: 30px;
  }
  .spec-project-container .project-tools #tools-container {
    max-width: 900px;
  }
  .spec-project-container .project-tools #tools-container a {
    width: 16%;
  }
}

#footer #upper-footer {
  padding: 50px;
  background-color: #f0cad4;
  text-align: center;
}

#footer #upper-footer h2 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 30px;
  margin-bottom: 40px;
}

#footer #upper-footer a {
  text-decoration: none;
  color: black;
}

#footer #upper-footer #link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#footer #upper-footer .link-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px;
}

#footer #upper-footer .link-card svg {
  margin: auto;
  font-size: 40px;
  margin-bottom: 10px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

#footer #upper-footer .link-card svg:hover {
  color: #247ba0;
  scale: 1.1;
}

#footer #upper-footer .link-card h4 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 20px;
}

#footer #upper-footer .link-card p {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
}

#footer #upper-footer #socials-container {
  margin-top: 50px;
}

#footer #upper-footer #socials-container svg {
  font-size: 30px;
  margin: 0 10px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

#footer #upper-footer #socials-container svg:hover {
  color: #247ba0;
}

#footer #lower-footer {
  background-color: #fefafb;
  width: 100%;
  padding: 20px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#footer #lower-footer p {
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
  font-size: 16px;
  color: #394148;
}

#footer #lower-footer p svg {
  font-size: 14px;
  color: #394148;
}

@media screen and (min-width: 768px) {
  #footer #upper-footer {
    padding: 70px 50px;
  }
  #footer #upper-footer h2 {
    font-family: "Julius Sans One", sans-serif;
    font-size: 25px;
    margin-bottom: 40px;
  }
  #footer #upper-footer #link-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  #footer #upper-footer .link-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 30px;
  }
  #footer #upper-footer .link-card svg {
    font-size: 50px;
  }
  #footer #upper-footer #socials-container {
    width: 100%;
    margin-top: 30px;
  }
  #footer #upper-footer #socials-container svg {
    font-size: 40px;
    margin: 0 15px;
  }
}

@media screen and (min-width: 1024px) {
  #footer #upper-footer {
    padding: 70px 50px;
  }
  #footer #upper-footer h2 {
    font-size: 35px;
  }
  #footer #upper-footer #link-container {
    max-width: 670px;
    margin: auto;
  }
}

@media screen and (min-width: 1440px) {
  #footer #upper-footer {
    padding: 70px 50px;
  }
}

@-webkit-keyframes image-fade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes image-fade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes text-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes tablet-fade-in-text {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tablet-fade-in-text {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-up-in-svg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(350px);
            transform: translateY(350px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-up-in-svg {
  0% {
    opacity: 0;
    -webkit-transform: translateY(350px);
            transform: translateY(350px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes about-img-fade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(300px);
            transform: translateY(300px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes about-img-fade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(300px);
            transform: translateY(300px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.slide-in-blurred-bottom {
  -webkit-animation: slide-in-blurred-bottom 3s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-bottom 3s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: #fefafb;
  min-height: 100vh;
  cursor: default;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  font-size: 25px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cursor {
  z-index: 999;
  position: fixed;
  background-color: #d67992;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  pointer-events: none;
  -webkit-box-shadow: 0 0 10px #d67992, 0 0 40px #d67992, 0 0 60px #d67992;
          box-shadow: 0 0 10px #d67992, 0 0 40px #d67992, 0 0 60px #d67992;
  -webkit-animation: colors 3s infinite;
          animation: colors 3s infinite;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: none;
}

@-webkit-keyframes colors {
  0% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}

@keyframes colors {
  0% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}

.cursor:before {
  content: '';
  position: absolute;
  background-color: #d67992;
  width: 30px;
  height: 30px;
  opacity: 0.2;
  -webkit-transform: translate(-33%, -33%);
          transform: translate(-33%, -33%);
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .scroll-to-top-button {
    font-size: 35px;
    bottom: 20px;
    right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .scroll-to-top-button {
    font-size: 40px;
    bottom: 40px;
    right: 40px;
  }
  .scroll-to-top-button:hover {
    color: #d67992;
  }
}

@media screen and (min-width: 1200px) {
  .scroll-to-top-button {
    font-size: 40px;
    bottom: 50px;
    right: 50px;
  }
}
